<template>
  <div>
    <v-row no-glutters class="body-rows">
      <!-- <v-col v-if="!hvcompount" cols="12" md="7" class="pa-0">
        <p class="subtitle-2 font-weight-bold mb-0 txt-666">
          <v-btn :disabled="cardloader" to="/declartaion" icon><v-icon>mdi-arrow-left</v-icon></v-btn> Step 5 of 6
        </p>
        <div style="height: 96vh;" class="">
          <iframe src="/hv?mode=zebu_wet_signature" title="HV" height="100%" width="100%" style="border: none"></iframe>
        </div>
      </v-col> -->

      <v-col cols="12" md="7" class="left-cols1 pr-md-8">
        <p class="subtitle-2 font-weight-bold mb-2 mb-md-5 txt-666">
          <v-btn :disabled="cardloader" to="/declartaion" icon><v-icon>mdi-arrow-left</v-icon></v-btn> Step 5 of 6
        </p>

        <div>
          <p class="headline font-weight-black mb-2">{{ signdialog == true ? "Sign Digitally" : "Signature verifications" }}</p>
          <p class="subtitle-1 font-weight-regular txt-666">
            <span v-if="signdialog">Sign digitally within the box</span>
            <span v-else>Sign digitally or upload your signature. Kindly ensure that your signature matches the one on your PAN card.</span>
          </p>
        </div>

        <v-alert v-if="rejectdata.app_status == 'rejected' ? rejectdata.stage_wise_approval.signature == 'no' : ''" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
          <span class="subtitle-2 font-weight-regular"
            >Your Signature have been rejected due <b>{{ rejectdata.sign_reject_reason }}</b></span
          >
        </v-alert>

        <div v-if="!dataloader" class="pt-4">
          <div v-if="signdialog != true && !signFile">
            <v-btn @click="hvcompount = true" :loading="hvcompount" height="46px" color="#444" outlined style="border: 2px solid #444 !important" class="text-none rounded-pill elevation-0"><span class="subtitle-1 font-weight-medium px-10">Add a Signature</span></v-btn>
          </div>
          <div v-if="hvcompount">
            <HyperVerge @setHVstat="getHVres" :mode="'zebu_wet_signature'" />
          </div>

          <!-- <div v-if="signdialog != true && !signFile" class="d-none d-md-inline-flex">
            <v-btn height="46px" color="#444" outlined style="border: 2px solid #444 !important" class="text-none rounded-pill elevation-0 mr-3" @click="document.getElementById('uploader').click()"
              ><span class="subtitle-1 font-weight-medium px-10">Upload sign</span>
              <v-file-input id="uploader" hide-details class="elevation-0 rounded-pill d-none" truncate-length="14" prepend-icon="" v-model="signFile" append-icon="" accept="image/png, image/jpg, image/jpeg" @change="manSignt" @input="manSignt"></v-file-input>
            </v-btn>
            <v-btn @click="signdialog = true" height="46px" color="#444" outlined style="border: 2px solid #444 !important" class="text-none rounded-pill elevation-0"><span class="subtitle-1 font-weight-medium px-10">Sign digitally</span></v-btn>
          </div>
          <v-row v-if="signdialog != true && !signFile" class="d-md-none">
            <v-col cols="12">
              <v-btn height="46px" color="#444" outlined style="border: 2px solid #444 !important" class="text-none rounded-pill elevation-0" block @click="document.getElementById('uploader').click()"
                ><span class="subtitle-1 font-weight-medium">Upload photo</span>
                <v-file-input id="uploader" hide-details class="elevation-0 rounded-pill d-none" truncate-length="14" prepend-icon="" v-model="signFile" append-icon="" accept="image/png, image/jpg, image/jpeg" @change="manSignt" @input="manSignt"></v-file-input>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn @click="signdialog = true" height="46px" color="#444" outlined block style="border: 2px solid #444 !important" class="text-none rounded-pill elevation-0"><span class="subtitle-1 font-weight-medium px-10">Sign digitally</span></v-btn>
            </v-col>
          </v-row> -->
          <div v-if="signdialog">
            <v-card outlined style="border: 1.4px dashed #cfd8dc !important" class="rounded-lg mb-16 mb-md-0" @click="signtbtn += 1">
              <vueSignature ref="signature" :sigOption="option" :w="'100%'" :h="'260px'" :disabled="disabled"> </vueSignature>
            </v-card>
            <v-row class="d-none d-md-flex">
              <v-col cols="6" class="text-right pl-6">
                <v-btn style="border: 2px solid #444 !important" outlined height="48px" @click="clear(), (signtbtn = 0)" color="#000" class="text-none rounded-pill elevation-0 mt-6" block><span class="subtitle-1 font-weight-medium px-6">Clear and draw again</span></v-btn>
              </v-col>
              <v-col cols="6" class="text-left pr-6">
                <v-btn height="48px" :disabled="signtbtn < 1" @click="save()" color="#000" class="white--text text-none rounded-pill elevation-0 mt-6" block><span class="white--text subtitle-1 font-weight-medium">Continue</span></v-btn>
              </v-col>
            </v-row>
            <v-footer padless style="position: absolute; bottom: 40px; left: 0; width: 100%" color="#fff" class="px-3 pt-3 pb-1 d-md-none">
              <v-row>
                <v-col cols="6">
                  <v-btn style="border: 2px solid #444 !important" outlined height="48px" @click="clear(), (signtbtn = 0)" color="#000" class="text-none rounded-pill elevation-0" block><span class="subtitle-1 font-weight-medium px-6">Clear</span></v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn height="48px" :disabled="signtbtn < 1" @click="save()" color="#000" class="white--text text-none rounded-pill elevation-0" block><span class="white--text subtitle-1 font-weight-medium">Continue</span></v-btn>
                </v-col>
              </v-row>
            </v-footer>
          </div>
          <!-- <div v-else>
                            <v-card outlined class="rounded-lg mb-4 text-center"
                                style="position: relative; overflow: hidden; height: 160px;">
                                <v-card class="elevation-0" color="transparent" @click="signDatac()"
                                    style="position: absolute; top: 0; right: 0;">
                                    <v-icon>mdi-close</v-icon>
                                </v-card>
                                <v-card v-if="signimagedata" class="rounded-t-0 rounded-b-lg pa-4 text-left" width="100%"
                                    height="80px" color="rgba(229, 235, 236, 0.96)"
                                    style="position:absolute; bottom: 0;left: 0;z-index: 3; box-shadow: 0px -8px 32px 0px rgba(0, 0, 0, 0.64);">
                                    <p class="mb-0">{{ signimagedata.name }}</p>
                                    <p>{{ signimagedata.size }} MB</p>
                                </v-card>
                                <iframe v-if="signimagedata.from == 'be'" style="border: none; border-radius: 8px;"
                                    :src="signimage" width="100%" height="160"></iframe>
                                
                            </v-card>
                            <v-file-input flat solo background-color="#ffffff"
                                class="txtfield elevation-0 rounded-pill d-none" small-chips truncate-length="14"
                                prepend-icon="" v-model="signFile" accept="image/png, image/jpg, image/jpeg"
                                label="Signature" :append-icon='signFile ? "" : "mdi-draw-pen"'
                                clear-icon='mdi-trash-can-outline'></v-file-input>
                        </div>
                        <v-row class="mt-6" no-gutters v-if="signFile">
                            <v-col cols="6" class="pr-2">
                                <v-btn color="#2A2A2A" class="elevation-0 rounded-pill text-none brd-2" block outlined
                                    @click="signdialog = false">
                                    <span>back</span>
                                </v-btn>
                            </v-col>
                            <v-col cols="6" class="pl-2">
                                <v-btn color="#2A2A2A" :disabled="!signFile" class="white--text text-none rounded-pill elevation-0"
                                    block><span class="white--text subtitle-1 font-weight-medium px-2">
                                        Next
                                    </span>
                                </v-btn>
                            </v-col>
                        </v-row> -->
          <div v-if="signimage && signFile && !signreject && !signdialog">
            <p>
              <span class="txt-666">File names</span> : <b>{{ signimagedata.name }}</b>
            </p>
            <v-card outlined style="border: 1.4px dashed #cfd8dc !important" class="rounded-lg mb-16 mb-md-0">
              <img class="rounded-lg" :src="signimage" style="max-height: 260px" width="100%" :alt="signimage" />
            </v-card>
            <v-row class="d-none d-md-flex">
              <v-col cols="6" class="text-right pl-6">
                <v-btn style="border: 2px solid #444 !important" outlined height="48px" @click="(signFile = null), (signimage = null)" color="#000" class="text-none rounded-pill elevation-0 mt-6" block><span class="subtitle-1 font-weight-medium px-6">Clear</span></v-btn>
              </v-col>
              <v-col cols="6" class="text-left pr-6">
                <v-btn height="48px" @click="fileUpload()" color="#000" class="white--text text-none rounded-pill elevation-0 mt-6" block><span class="white--text subtitle-1 font-weight-medium">Continue</span></v-btn>
              </v-col>
            </v-row>
            <v-footer padless style="position: absolute; bottom: 40px; left: 0; width: 100%" color="#fff" class="px-3 pt-3 pb-1 d-md-none">
              <v-row>
                <v-col cols="6">
                  <v-btn style="border: 2px solid #444 !important" outlined height="48px" @click="(signFile = null), (signimage = null)" color="#000" class="text-none rounded-pill elevation-0" block><span class="subtitle-1 font-weight-medium px-6">Clear</span></v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn height="48px" @click="fileUpload()" color="#000" class="white--text text-none rounded-pill elevation-0" block><span class="white--text subtitle-1 font-weight-medium">Continue</span></v-btn>
                </v-col>
              </v-row>
            </v-footer>
          </div>
          <div v-if="signreject && signimagedata.from == 'be' && !signdialog">
            <p>
              <span class="txt-666">File names</span> : <b>{{ signimagedata.name }}</b>
            </p>
            <v-card outlined style="border: 1.4px dashed #cfd8dc !important" class="rounded-lg mb-16 mb-md-0">
              <img class="rounded-lg" :src="signimage" style="max-height: 260px" width="100%" :alt="signimage" />
            </v-card>

            <v-row class="d-none d-md-flex">
              <v-col cols="6" class="text-right pl-6">
                <v-btn style="border: 2px solid #444 !important" outlined height="48px" @click="(signFile = null), (signimage = null), (signimagedata = []), (signreject = false), $emit('changed', 5)" color="#000" class="text-none rounded-pill elevation-0 mt-6 mr-4" block
                  ><span class="subtitle-1 font-weight-medium px-6">Clear</span></v-btn
                >
              </v-col>
              <v-col cols="6" class="text-left pr-6">
                <v-btn height="48px" @click="toNext()" color="#000" class="white--text text-none rounded-pill elevation-0 mt-6" block><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn>
              </v-col>
            </v-row>
            <v-footer padless style="position: absolute; bottom: 40px; left: 0; width: 100%" color="#fff" class="px-3 pt-3 pb-1 d-md-none">
              <v-row>
                <v-col cols="6">
                  <v-btn style="border: 2px solid #444 !important" outlined height="48px" @click="(signFile = null), (signimage = null), (signimagedata = []), (signreject = false), $emit('changed', 5)" color="#000" class="text-none rounded-pill elevation-0" block
                    ><span class="subtitle-1 font-weight-medium px-6">Clear</span></v-btn
                  >
                </v-col>
                <v-col cols="6">
                  <v-btn height="48px" @click="toNext()" color="#000" class="white--text text-none rounded-pill elevation-0" block><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn>
                </v-col>
              </v-row>
            </v-footer>
          </div>
        </div>
        <div v-else>
          <v-card width="100%" class="text-center py-16 elevation-0 mt-10">
            <v-progress-circular class="mx-auto" :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
          </v-card>
        </div>
      </v-col>
      <v-col cols="5" class="rgt-cols d-none d-md-block pt-0">
        <v-container fill-height class="d-block text-center">
          <img src="@/assets/sign.svg" class="rgt-img" alt="" />
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HyperVerge from "../components/HyperVerge.vue";
import axios from "axios";
import api from "@/api";
// import vueSignature from "vue-signature";

export default {
  data: () => ({
    document,
    other: true,
    ///////////////
    signreject: false,
    signFile: null,
    cardloader: false,
    //
    signdialog: false,
    signimage: null,
    signimagedata: [],
    link: "#",
    signtbtn: 0,
    option: {
      penColor: "rgb(0, 0, 0)",
      backgroundColor: "rgb(255,255,255)",
    },
    disabled: false,
    dataloader: null,
    signtype: "",
    rejectdata: [],
    hvcompount: false,
  }),
  components: {
    // vueSignature,
    HyperVerge,
  },
  created() {
    let config = {
      method: "post",
      url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")),
      headers: {
        "Content-Type": "application/json",
      },
    };

    let axiosThis = this;
    axios
      .request(config)
      .then((response) => {
        let p = response.data.data_type;
        if (response.data.data_type && response.data.stage_wise_approval.signature != "yes" && (p.stage == "signature" || p.stage == "nominee" || p.stage == "selfie" || p.stage == "submit")) {
          axiosThis.$emit("clicked", 5, response.data);
          response.data.app_status == "rejected" && response.data.stage_wise_approval.signature == "no" ? (axiosThis.rejectdata = response.data) : (axiosThis.rejectdata = []);
        } else {
          axiosThis.$router.push(p ? {name: p.stage} : "/");
        }
      })
      .catch((error) => {
        console.log(error);
        axiosThis.$emit("snack", 0, error);
      });
  },

  methods: {
    async getHVres(res, clr, msg) {
      if (clr == 1 && res) {
        let data = JSON.stringify({
          url: res.details.Wet_Signature_Cropped_Image_of_Signature,
        });

        let axiosThis = this;
        let config = {
          method: "post",
          url: `${api.api_url}/HypervergeDownload`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            if (response.data) {
              axiosThis.signFile = axiosThis.dataURLtoFile(response.data, `${res.details.Wet_Signature_Signature_Mode == "Sign on Screen" ? `D-HYV` : "M-HYV"}-signature.png`);
              axiosThis.signtype = res.details.Wet_Signature_Signature_Mode == "Sign on Screen" ? `digital HYV` : "manual HYV";
              axiosThis.signreject = false;
              axiosThis.signtbtn = 0;
              this.signimage = window.URL.createObjectURL(this.signFile);
              axiosThis.signimagedata["name"] = axiosThis.signFile.name;
              axiosThis.signimagedata["size"] = (axiosThis.signFile.size / 1048576).toFixed(1);
              axiosThis.fileUpload(res);
            }
          })
          .catch((error) => {
            console.log(error);
            axiosThis.$emit("snack", 0, error);
          });
      } else {
        this.$emit("snack", clr, msg);
        this.hvcompount = false;
      }
    },

    toNext() {
      this.$emit("changed", 0);
      if (this.rejectdata.app_status == "rejected" && this.rejectdata.stage_wise_approval.signature == "no") {
        this.$emit("steged", "signature");
      } else {
        this.$emit("clicked", "nominee");
      }
    },
    signDatac() {
      this.signFile = "";
      this.signimagedata = [];
      this.signimage = "";
      this.signreject = false;
    },

    manSignt() {
      if (this.signFile.size <= 4000000) {
        this.signimage = window.URL.createObjectURL(this.signFile);
        this.signimagedata["name"] = this.signFile.name;
        this.signimagedata["size"] = (this.signFile.size / 1048576).toFixed(1);
      } else {
        this.$emit("snack", 2, this.signFile.size > 4000000 ? "Img size should be less than 4 MB!" : "Only png, jpg, jpeg and PDF file formats are supported.");
        this.signFile = "";
        this.signimage = null;
        this.signimagedata = [];
        this.signreject = false;
      }
    },

    dataURLtoFile(dataurl, filename) {
      const byteCharacters = atob(dataurl);
      const byteArray = new Uint8Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArray[i] = byteCharacters.charCodeAt(i);
      }
      const blob = new Blob([byteArray], {type: "image/png"});
      const last = new File([blob], filename, {type: "image/png"});
      return last;
      // var arr = dataurl.split(","),
      //   mime = arr[0].match(/:(.*?);/)[1],
      //   bstr = atob(arr[1]),
      //   n = bstr.length,
      //   u8arr = new Uint8Array(n);
      // while (n--) {
      //   u8arr[n] = bstr.charCodeAt(n);
      // }
      // return new File([u8arr], filename, {type: mime});
    },
    save() {
      var _this = this;
      var png = _this.$refs.signature.save();
      _this.signFile = this.dataURLtoFile(png, "D-signature.png");
      _this.signtbtn = 0;
      _this.signimage = png;
      _this.signimagedata["name"] = _this.signFile.name;
      _this.signimagedata["size"] = (_this.signFile.size / 1048576).toFixed(1);
      _this.signtype = "digital";
      _this.fileUpload();
    },
    clear() {
      var _this = this;
      _this.$refs.signature.clear();
    },
    getFileis(name, link) {
      let config = {
        method: "post",
        url: api.api_url + `/filsend?path=${link}`,
        // url: '192.168.5.132:5001/zebu/filsend?path=static/Test/DigiDownload/9994535621/option trading strategies.pdf',
        headers: {
          "Content-Type": "application/json",
        },
      };

      var axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          if (response.data) {
            let hash = atob(localStorage.getItem("hash"));

            if (name == "DS") {
              let url = `${api.api_img}/${link}`;
              let blob = new Blob([url]);
              let file = new File([blob], `${url.split(`${hash}/`)[1].split(".")[0]}`, {type: `image/${url.split(`${hash}/`)[1].split(".")[1]}`});
              axiosThis.signimage = url;
              axiosThis.signFile = file;
              axiosThis.signimagedata["name"] = this.signFile.name;
              axiosThis.signimagedata["size"] = (this.signFile.size / 1048576).toFixed(1);
              axiosThis.signimagedata["from"] = "be";
              axiosThis.signdialog = false;
            }
            axiosThis.dataloader = false;
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.$emit("snack", 0, error);
        });
    },
    fileUpload(hyv) {
      if (this.signreject == false && this.signFile && (this.signFile["type"] == "image/jpeg" || this.signFile["type"] == "image/jpg" || this.signFile["type"] == "image/png")) {
        this.cardloader = true;
        let data = JSON.stringify({
          signtype: this.signtype,
          // HYV_sig_res: hyv ? JSON.stringify(hyv) : "",/
        });
        let config = {
          method: "post",
          url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")) + `&type=add`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then(() => {})
          .catch((error) => {
            console.log(error);
          });

        let formData = new FormData();

        if (this.signreject == false && this.signFile) {
          formData.append("signature", this.signFile);
          formData.append("HYV_sig_res", hyv ? JSON.stringify(hyv) : "");
          // formData.append("IPVlocation", this.geolocation ? this.geolocation : "");
          // formData.append("IPaddress", this.ipaddress ? this.ipaddress : "");
        }
        var axiosThis = this;

        axios
          .post(api.api_url + `/uploadSignupUserFiles/` + atob(localStorage.getItem("hash")), formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            // data: data
          })
          .then(function (response) {
            if (response.data["msg"] == "All Files Upload sucessfully") {
              if (axiosThis.rejectdata.app_status == "rejected" && axiosThis.rejectdata.stage_wise_approval.signature == "no") {
                axiosThis.$emit("steged", "signature");
              } else {
                axiosThis.$emit("clicked", "nominee");
              }
            }
          })
          .catch(function () {
            axiosThis.cardloader = false;
          });
      } else {
        this.hvcompount = false;
        this.cardloader = false;
        this.$emit("snack", 2, "Only png, jpg, jpeg image formats are supported.");
      }
    },
  },
  mounted() {
    this.dataloader = true;
    var config = {
      method: "post",
      url: api.api_url + "/check_file?mob=" + atob(localStorage.getItem("hash")),
      headers: {},
    };
    var axiosThis = this;
    axios(config)
      .then(function (response) {
        if (response.data != "no file data found") {
          axiosThis.signreject = response.data["Sign File"] == true ? true : false;
          if (axiosThis.rejectdata.app_status == "rejected" && axiosThis.rejectdata.stage_wise_approval.signature == "no") {
            axiosThis.signreject = false;
            axiosThis.dataloader = false;
          } else if (response.data["Sign File"] == true) {
            let name = "DS";
            let link = response.data["Sign"];
            axiosThis.getFileis(name, link);
          } else {
            axiosThis.dataloader = false;
          }
        } else {
          axiosThis.dataloader = false;
        }
      })
      .catch(function (error) {
        console.log(error);
        axiosThis.$emit("snack", 0, error);
      });
  },
};
</script>
